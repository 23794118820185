/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type JobPositionDetailFragment = {
  __typename?: 'JobPosition';
  id: any;
  archivedAt?: any | null;
  occupationalHazards: Array<Types.JobPositionHazardChoices>;
  shiftWorkType: Types.JobPositionShiftWorkTypeChoices;
  shiftDurationHours: Types.JobPositionShiftDurationChoices;
  name: string;
  typeOfWork: string;
  czIsco: string;
  workEnvironmentCharacteristics: string;
  qualificationRequirements: string;
  responsibilities: string;
  extraordinaryWorkConditions: string;
  riskPreventionMeasures: string;
  meAllowedOperations: Array<Types.PermissionOperationChoices>;
  maxRiskFactorValue?: Types.JobPositionRiskValueChoices | null;
};

export type JobPositionDetailQueryVariables = Types.Exact<{
  jobPositionDetailId: Types.Scalars['JobPositionID']['input'];
}>;

export type JobPositionDetailQuery = {
  __typename?: 'Query';
  object:
    | {
        __typename?: 'JobPosition';
        id: any;
        archivedAt?: any | null;
        occupationalHazards: Array<Types.JobPositionHazardChoices>;
        shiftWorkType: Types.JobPositionShiftWorkTypeChoices;
        shiftDurationHours: Types.JobPositionShiftDurationChoices;
        name: string;
        typeOfWork: string;
        czIsco: string;
        workEnvironmentCharacteristics: string;
        qualificationRequirements: string;
        responsibilities: string;
        extraordinaryWorkConditions: string;
        riskPreventionMeasures: string;
        meAllowedOperations: Array<Types.PermissionOperationChoices>;
        maxRiskFactorValue?: Types.JobPositionRiskValueChoices | null;
      }
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null };
};

export const JobPositionDetailFragmentDoc = gql`
  fragment JobPositionDetail on JobPosition {
    id
    archivedAt
    occupationalHazards
    shiftWorkType
    shiftDurationHours
    name
    typeOfWork
    czIsco
    workEnvironmentCharacteristics
    qualificationRequirements
    responsibilities
    extraordinaryWorkConditions
    riskPreventionMeasures
    meAllowedOperations
    maxRiskFactorValue
  }
`;
export const JobPositionDetailDocument = gql`
  query JobPositionDetail($jobPositionDetailId: JobPositionID!) {
    object: jobPositionDetail(id: $jobPositionDetailId) {
      ... on JobPosition {
        ...JobPositionDetail
      }
      ... on ObjectDoesNotExistResponse {
        objectId
      }
    }
  }
  ${JobPositionDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class JobPositionDetailGQL extends Apollo.Query<JobPositionDetailQuery, JobPositionDetailQueryVariables> {
  override document = JobPositionDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
